<template>
    <el-main>
        <el-button style="margin-bottom:10px;" size="small" type="primary" icon="el-icon-plus" @click="add">添加物业</el-button>
        <el-button size="small" @click="showImport = !0">导入物业</el-button>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="物业名称：">
                <el-input size="small" v-model="searchForm.property_name" placeholder="请输入物业名称"></el-input>
            </el-form-item>
            <el-form-item label="负责人姓名：">
                <el-input size="small" v-model="searchForm.duty_name" placeholder="请输入负责人姓名"></el-input>
            </el-form-item>
            <el-form-item label="联系电话：">
                <el-input size="small" type="number" v-model="searchForm.mobile" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="添加时间：">
                <el-date-picker size="small" v-model="searchForm.start_time" type="daterange" range-separator="~"
                    start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button size="small" type="primary" @click="getList()">搜索</el-button>
                <el-button size="small" type="text" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="property_name" label="物业名称" align="center"></el-table-column>
            <el-table-column prop="duty_name" label="负责人姓名" align="center"></el-table-column>
            <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
            <el-table-column prop="rate" label="结算费率" align="center">
                <template v-slot="{ row }">
                    {{ row.rate }}%
                </template>
            </el-table-column>
            <el-table-column prop="property_account" label="登录账号" align="center"></el-table-column>
            <el-table-column prop="create_time" label="添加时间" width="200" align="center">
                <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
            </el-table-column>
            <el-table-column label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
                    <el-button @click="remove(scope.row.id, scope.$index)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
        </Paging>
        <el-dialog :title="type ? '编辑物业' : '添加物业'" :visible.sync="showAdd" width="30%" :before-close="handleClose">
            <el-form ref="form" class="add" :model="addForm" :rules="rules" label-width="110px">
                <el-form-item label="物业名称：" required="true">
                    <el-input v-model="addForm.property_name"></el-input>
                </el-form-item>
                <el-form-item label="负责人姓名：" required="true">
                    <el-input v-model="addForm.duty_name"></el-input>
                </el-form-item>
                <el-form-item label="联系电话：" required="true">
                    <el-input v-model="addForm.mobile" type="number"></el-input>
                </el-form-item>
                <el-form-item label="结算费率：" required="true">
                    <el-input v-model="addForm.rate" type="number" min="0.00" max="100.00" step="0.01"><template
                            slot="append">%</template></el-input>
                </el-form-item>
                <el-form-item label="登录密码：" required="true">
                    <el-input v-model="addForm.property_password"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showAdd = !1">取 消</el-button>
                <el-button type="primary" @click="addSubmit">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="导入物业" :visible.sync="showImport" width="550px">
            <el-link type="primary" :href="templatePath">下载导入模板</el-link>
            <el-input class="upload" v-model="filePath">
                <el-button slot="append">
                    上传文件
                    <input type="file" ref="inputer" @change="getFile" />
                </el-button>
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showImport = !1">取 消</el-button>
                <el-button type="primary" @click="confirmImport">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>
<script>
import _ from 'lodash';
import Paging from '@/components/paging';
import { matchDataAssign } from '@/util/util';
import { getDateformat } from '@/util/getDate';
import config from '@/util/config';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                property_name: '',
                duty_name: '',
                mobile: '',
                start_time: '',
                end_time: '',
            },
            showAdd: !1,
            type: 0,
            id: '',
            addForm: {
                property_name: '',
                duty_name: '',
                mobile: '',
                rate: 0,
                property_password: '',
            },
            showImport: !1,
            formData: '',
            filePath: '',
            templatePath: ''
        };
    },
    created () {
        this.getList();
        this.getTemplatePath()
    },
    methods: {
        search () {
            this.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                property_name: '',
                duty_name: '',
                mobile: '',
                start_time: '',
                end_time: '',
            };
            this.getList();
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        add () {
            this.type = 0;
            this.showAdd = !0;
            this.addForm = {
                property_name: '',
                duty_name: '',
                mobile: '',
                rate: 80,
                property_password: '',
            };
        },
        // 调用接口时检测数据是否合法
        checkDataLegitimacy () {
            if (!this.addForm.property_name) {
                this.$message.warning('请填写物业名称');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.addForm.duty_name) {
                this.$message.warning('请填写负责人姓名');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.addForm.mobile) {
                this.$message.warning('请填写联系电话');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.addForm.rate) {
                this.$message.warning('请填写结算费率');
                this.activeTabs = 'one';
                return false;
            }
            if (!this.addForm.property_password) {
                this.$message.warning('请填写登录密码');
                this.activeTabs = 'one';
                return false;
            }
        },
        addSubmit () {
            let checkRes = this.checkDataLegitimacy();
            if (checkRes === false) return;
            let url = this.type ? this.$api.repair.property.edit : this.$api.repair.property.add;
            let data = {
                property_name: this.addForm.property_name,
                duty_name: this.addForm.duty_name,
                mobile: this.addForm.mobile,
                rate: this.addForm.rate,
                property_password: this.addForm.property_password,
            };
            if (this.type) data.id = this.id;
            this.$axios.post(url, data).then(res => {
                if (res.code == 0) {
                    this.showAdd = !1;
                    this.getList();
                    this.$message({
                        message: `${this.type ? '编辑成功' : '添加成功'}`,
                        type: 'success',
                    });
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        // 获取物业列表
        getList () {
            let searchForm = _.cloneDeep(this.searchForm);
            if (searchForm.start_time) {
                searchForm.end_time = searchForm.start_time[1] / 1000;
                searchForm.start_time = searchForm.start_time[0] / 1000;
            }
            this.$axios.post(this.$api.repair.property.list, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        edit (row) {
            this.type = 1;
            this.showAdd = !0;
            this.addFrom = matchDataAssign(this.addForm, row);
            this.id = row.id;
        },
        remove (id, index) {
            this.$confirm('确认删除此物业？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(this.$api.repair.property.del, {
                        id: id,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                            });
                            this.list.splice(index, 1);
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        getTemplatePath () {
            this.$axios.post(this.$api.repair.property.getTemplate)
                .then(res => {
                    if (res.code == 0) {
                        this.templatePath = config.baseurl + '/' + res.result
                    }
                });
        },
        getFile () {
            let files = this.$refs.inputer.files[0];
            let formData = new FormData();
            formData.append('file', files);
            this.filePath = files.name;
            this.formData = formData;
        },
        confirmImport () {
            if (!this.formData) return this.$message.warning('请先上传文件');
            this.$axios.uploadFile(this.$api.repair.property.import, this.formData).then(res => {
                if (res.code == 0) {
                    this.showImport = !1;
                    this.$message.success('物业导入成功');
                    this.getList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .el-table {
        border-left: 1px solid #e8eef1;
        border-right: 1px solid #e8eef1;

        /deep/ .el-input__inner {
            border-color: #fff;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }
    }

    .upload {
        margin-top: 20px;

        .el-button {
            position: relative;

            input {
                opacity: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
            }
        }
    }
}
</style>
